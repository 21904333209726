import { RecordType } from '@xbcb/shared-types';
import {
  DeIor,
  GbIor,
  NlIor,
  FrIor,
  UsIor,
  GbConsignee,
  DeConsignee,
  NlConsignee,
  FrConsignee,
  GbIorActivationDetails,
  DeIorActivationDetails,
  NlIorActivationDetails,
  FrIorActivationDetails,
} from '@xbcb/api-gateway-client';

export const usIorCbpEntryPreferredPaymentMethodDescription = `The US IORs preferred payment method on an entry`;
export const UsIorPaymentTermsDescription = `Payment terms offered to US IOR`;
export enum UsIorCbpEntryPreferredPaymentMethod {
  // Cbp payment type code 1
  CHECK = 'CHECK',
  // Deprecated on 7/16/2020
  DAILY_STATEMENT = 'DAILY_STATEMENT',
  // Deprecated on 7/16/2020
  PERIODIC_MONTHLY_STATEMENT = 'PERIODIC_MONTHLY_STATEMENT',
  // Cbp payment type code 3
  DIRECT_DAILY_STATEMENT = 'DIRECT_DAILY_STATEMENT',
  // Cbp payment type code 7
  DIRECT_PERIODIC_MONTHLY_STATEMENT = 'DIRECT_PERIODIC_MONTHLY_STATEMENT',
  // Cbp payment type code 2
  INDIRECT_DAILY_STATEMENT = 'INDIRECT_DAILY_STATEMENT',
  // Cbp payment type code 6
  INDIRECT_PERIODIC_MONTHLY_STATEMENT = 'INDIRECT_PERIODIC_MONTHLY_STATEMENT',
}

export enum UsIorNumberType {
  SSN = 'SSN',
  EIN = 'EIN',
  CBP = 'CBP',
}

export enum UsBusinessStructure {
  CORPORATION = 'CORPORATION',
  PARTNERSHIP = 'PARTNERSHIP',
  INDIVIDUAL = 'INDIVIDUAL',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  FOREIGN_GOVERNMENT = 'FOREIGN_GOVERNMENT',
  US_GOVERNMENT = 'US_GOVERNMENT',
  STATE_GOVERNMENT = 'STATE_GOVERNMENT',
  LLC = 'LLC',
}

export enum BusinessStructure {
  CORPORATION = 'CORPORATION',
  PARTNERSHIP = 'PARTNERSHIP',
  INDIVIDUAL = 'INDIVIDUAL',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  LLC = 'LLC',
  LLP = 'LLP',
  LP = 'LP',
}

export enum UsIorCbpStatus {
  NOT_ON_FILE = 'NOT_ON_FILE',
  ACTIVE_WITH_BOND = 'ACTIVE_WITH_BOND',
  ACTIVE_NO_BOND = 'ACTIVE_NO_BOND',
  VOIDED = 'VOIDED',
  INACTIVE = 'INACTIVE',
}

export enum UsIorPmsStatus {
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UsIorConfidentialityStatus {
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UsIorStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export enum UsCbp5106RelatedBusinessType {
  CURRENT = 'CURRENT',
  PREVIOUS = 'PREVIOUS',
}

export enum UsCbpImporterProgramCode {
  CTPAT = 'CTPAT',
  ISA = 'ISA',
  AEO = 'AEO',
  PIP = 'PIP',
}

export enum UsIorCbpAssignedNumberReason {
  /* I have an SSN, but wish to use a CBP-Assigned Number on all my entry documents */
  HAS_SSN_NEEDS_CBP = 'HAS_SSN_NEEDS_CBP',
  /* I have no Social Security Number */
  NO_SSN = 'NO_SSN',
  /* I have no IRS Number */
  NO_IRS = 'NO_IRS',
  /* I have not applied for an IRS number or SSN */
  NO_IRS_OR_SSN = 'NO_IRS_OR_SSN',
  /* NOT_US_RESIDENT */
  NOT_US_RESIDENT = 'NOT_US_RESIDENT',
}

export enum UsIorFirstTimeStatus {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKOWN', // TODO this is mispelled
}

export enum UsCbp5106EntriesPerYearCode {
  ONE_TO_FOUR = 'ONE_TO_FOUR',
  FIVE_TO_TWENTY_FIVE = 'FIVE_TO_TWENTY_FIVE',
  TWENTY_FIVE_OR_MORE = 'TWENTY_FIVE_OR_MORE',
  INFREQUENT_PERSONAL_SHIPMENTS = 'INFREQUENT_PERSONAL_SHIPMENTS',
  DO_NOT_INTEND_TO_IMPORT = 'DO_NOT_INTEND_TO_IMPORT',
}
export enum UsCbp5106AddressTypeCode {
  RESIDENCE = 'RESIDENCE',
  CORPORATE_OFFICE = 'CORPORATE_OFFICE',
  WAREHOUSE = 'WAREHOUSE',
  RETAIL_LOCATION = 'RETAIL_LOCATION',
  OFFICE_BUILDING = 'OFFICE_BUILDING',
  BUSINESS_SERVICE_CENTER = 'BUSINESS_SERVICE_CENTER',
  POST_OFFICE_BOX = 'POST_OFFICE_BOX',
  OTHER = 'OTHER',
}

export enum UsIorActivationStatus {
  PENDING_MASTER_SUB_CERT = 'PENDING_MASTER_SUB_CERT',
  PENDING_MASTER_SUB = 'PENDING_MASTER_SUB',
  PENDING_MASTER = 'PENDING_MASTER',
  PENDING_SUB = 'PENDING_SUB',
  PENDING_DIRECT_CERT = 'PENDING_DIRECT_CERT',
  PENDING_DIRECT = 'PENDING_DIRECT',
  PENDING_CERT = 'PENDING_CERT',
  PENDING_MASTER_CERT = 'PENDING_MASTER_CERT',
  PENDING_SUB_CERT = 'PENDING_SUB_CERT',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  PENDING_INQUIRY = 'PENDING_INQUIRY',
  ACTIVATED = 'ACTIVATED',
  DELETED = 'DELETED',
}

export enum UsIorPaymentTerms {
  PMS = 'PMS',
  DAILY_STATEMENT = 'DAILY_STATEMENT',
  PREPAID = 'PREPAID',
}
export enum ActivationStatus {
  PENDING_MASTER_SUB_CERT = 'PENDING_MASTER_SUB_CERT',
  PENDING_MASTER_SUB = 'PENDING_MASTER_SUB',
  PENDING_MASTER = 'PENDING_MASTER',
  PENDING_SUB = 'PENDING_SUB',
  PENDING_DIRECT_CERT = 'PENDING_DIRECT_CERT',
  PENDING_DIRECT = 'PENDING_DIRECT',
  PENDING_CERT = 'PENDING_CERT',
  PENDING_MASTER_CERT = 'PENDING_MASTER_CERT',
  PENDING_SUB_CERT = 'PENDING_SUB_CERT',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  PENDING_INQUIRY = 'PENDING_INQUIRY',
  ACTIVATED = 'ACTIVATED',
  DELETED = 'DELETED',
  REJECTED = 'REJECTED',
}

export enum POAMethod {
  DIRECT = 'DIRECT',
  MASTER_SUB = 'MASTER_SUB',
}

export enum BondUserStatusIndicator {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}

export enum ShipperStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export enum ShipperExternalDetailsSource {
  AFT = 'AFT',
}

export enum BrokerRejectionReason {
  INCOMPLETE_MASTER_POA = 'INCOMPLETE_MASTER_POA',
  INCOMPLETE_SUB_POA = 'INCOMPLETE_SUB_POA',
  INCOMPLETE_SUPPORTING_DOCUMENTATION = 'INCOMPLETE_SUPPORTING_DOCUMENTATION',
  INVALID_EORI_NUMBER = 'INVALID_EORI_NUMBER',
  INVALID_VAT_NUMBER = 'INVALID_VAT_NUMBER',
  EORI_MISMATCH_BETWEEN_POA_AND_IOR_PROFILE = 'EORI_MISMATCH_BETWEEN_POA_AND_IOR_PROFILE',
  VAT_MISMATCH_BETWEEN_POA_AND_IOR_PROFILE = 'VAT_MISMATCH_BETWEEN_POA_AND_IOR_PROFILE',
  COMPANY_NAME_MISMATCH_BETWEEN_VAT_AND_EORI = 'COMPANY_NAME_MISMATCH_BETWEEN_VAT_AND_EORI',
  INVALID_MASTER_POA_SIGNATURE = 'INVALID_MASTER_POA_SIGNATURE',
  INVALID_SUB_POA_SIGNATURE = 'INVALID_SUB_POA_SIGNATURE',
  MASTER_POA_SIGNATURE_NOT_IN_ENGLISH = 'MASTER_POA_SIGNATURE_NOT_IN_ENGLISH',
  SUB_POA_SIGNATURE_NOT_IN_ENGLISH = 'SUB_POA_SIGNATURE_NOT_IN_ENGLISH',
  COMPANY_ADDRESS_MISMATCH_BETWEEN_POA_AND_IOR_PROFILE = 'COMPANY_ADDRESS_MISMATCH_BETWEEN_POA_AND_IOR_PROFILE',
  INSUFFICIENT_INFORMATION_FOR_DUE_DILIGENCE_CHECKS = 'INSUFFICIENT_INFORMATION_FOR_DUE_DILIGENCE_CHECKS',
  GOODS_IMPORT_OUTSIDE_OF_BROKER_BUSINESS_SCOPE = 'GOODS_IMPORT_OUTSIDE_OF_BROKER_BUSINESS_SCOPE',
  GOODS_IMPORT_EXCEEDS_BROKER_COMPLIANCE_RISK_THRESHOLD = 'GOODS_IMPORT_EXCEEDS_BROKER_COMPLIANCE_RISK_THRESHOLD',
  NON_COMPLIANCE_WITH_GOVERNMENT_RULES_OR_REGULATIONS = 'NON_COMPLIANCE_WITH_GOVERNMENT_RULES_OR_REGULATIONS',
  INSUFFICIENT_MASTER_POA_SIGNATORY_TITLE = 'INSUFFICIENT_MASTER_POA_SIGNATORY_TITLE',
  INSUFFICIENT_SUB_POA_SIGNATORY_TITLE = 'INSUFFICIENT_SUB_POA_SIGNATORY_TITLE',
}

export enum BrokerRepresentation {
  DIRECT = 'DIRECT',
  INDIRECT = 'INDIRECT',
}

export enum ExternalIorReferenceType {
  FBA = 'FBA',
}

export enum UsConsumptionEntryInvoiceTriggerMilestoneName {
  ENTRY_FILED = 'ENTRY_FILED',
  CARGO_RELEASE = 'CARGO_RELEASE',
}

export const BrokerRejectionReasonByRecordType = {
  [RecordType.GB_IOR_ACTIVATION]: Object.keys(BrokerRejectionReason),
  [RecordType.DE_IOR_ACTIVATION]: Object.keys(BrokerRejectionReason),
  [RecordType.NL_IOR_ACTIVATION]: Object.keys(BrokerRejectionReason),
  [RecordType.FR_IOR_ACTIVATION]: Object.keys(BrokerRejectionReason),
  [RecordType.IMPORTER_ENTITY_ACTIVATION]: Object.keys(BrokerRejectionReason),
};

export type BrokerRejectionReasonByRecordType =
  typeof BrokerRejectionReasonByRecordType;

export type CountryIor = UsIor | GbIor | DeIor | NlIor | FrIor;

export type EuIor = GbIor | DeIor | NlIor | FrIor;

export type EuIorActivationDetails =
  | GbIorActivationDetails
  | DeIorActivationDetails
  | NlIorActivationDetails
  | FrIorActivationDetails;

export type EuConsignee = GbConsignee | DeConsignee | NlConsignee | FrConsignee;

export enum BrokerEntityName {
  INLT = 'INLT',
  ZENITH = 'ZENITH',
  AVASK = 'AVASK',
  DELOITTE = 'DELOITTE',
  REGIONAL_EXPRESS = 'REGIONAL_EXPRESS',
}

export enum PartyIdentificationType {
  VAT_NUMBER = 'VAT_NUMBER',
  DUNS = 'DUNS',
  EIN = 'EIN',
  CBP_ASSIGNED_NUMBER = 'CBP_ASSIGNED_NUMBER',
  SSN = 'SSN',
  EORI = 'EORI',
  BUSINESS_LICENSE_NUMBER = 'BUSINESS_LICENSE_NUMBER',
  // Importer/Exporter's code, Corporate ID Number, Japan Consumption Tax ID are used for JP Importers.
  IMPORTER_EXPORTER_CODE = 'IMPORTER_EXPORTER_CODE',
  CORPORATE_ID_NUMBER = 'CORPORATE_ID_NUMBER',
  JCT_ID = 'JCT_ID',
}
